import React from 'react'
import {Link, graphql} from 'gatsby'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Sidebar from "../components/sidebar";
import Quote from "../components/quote";

class QuoteIndex extends React.Component {
    render() {
        const {data} = this.props;

        const siteTitle = data.site.siteMetadata.title;
        const posts = data.allMarkdownRemark.edges;
        const {currentPage, numPages} = this.props.pageContext;
        let {postType} = this.props.pageContext;
        const isFirst = currentPage === 1;
        const isLast = currentPage === numPages;
        const prevPageNum = (currentPage - 1);
        let prevPage = '/';

        if (typeof postType === 'undefined') {
            postType = 'blog';
        }

        if (postType !== 'blog' || prevPageNum > 1) {
            prevPage += postType;
        }

        if (prevPageNum > 1) {
            prevPage += '/' + prevPageNum.toString();
        }

        const nextPage = '/' + postType + '/' + (currentPage + 1).toString();

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <Seo
                    title={siteTitle}
                />

                <Sidebar music={data.music}/>

                <div className="main-content">
                    <ol className="content post-list">
                        {posts.map(({node}) => {
                            return (
                                <li key={node.frontmatter.slug}>
                                    <Quote quote={node}/>
                                </li>
                            )
                        })}
                    </ol>

                    <ul className="prev-next">
                        {!isFirst && (
                            <li>
                                <Link to={prevPage} rel="prev" className="prev-next-link link-previous">
                                    Previous Page
                                </Link>
                            </li>
                        )}

                        <li>
                            <Link to="/quotes/random">Random quote</Link>
                        </li>

                        {!isLast && (
                            <li>
                                <Link to={nextPage} rel="next" className="prev-next-link link-next">
                                    Next Page
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </Layout>
        )
    }
}

export default QuoteIndex

export const pageQuery = graphql`
  query quoteListQuery(
    $skip: Int!
    $limit: Int!
    $contentType: String!
    $musicId: String
    ) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
        filter: {frontmatter: {contentType: {eq: $contentType}}}
        sort: { fields: [frontmatter___date], order: DESC }
        limit: $limit
        skip: $skip
    ) {
      edges {
        node {
            html
            excerpt
            fields {
                slug
            }
            frontmatter {
                date(formatString: "DD MMMM, YYYY")
                title
                slug
                tags
                author
                source
                source_link
                amazon_id
            }
        }
      }
    }
    music: markdownRemark(id: { eq: $musicId }) {
        id
        frontmatter {
          title
          slug
          featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
        html
    }
  }
`;
